html,
body {
  font-family: 'Roboto', serif;
  overscroll-behavior: none;
  overscroll-behavior-x: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Alfa Slab One', serif;
}

.app-view {
  position: absolute;
  width: 100vw;
  height: 100%;
  left: 0px;
  top: 0px;
}

button {
  border-radius: 9999px;
}
